@import url('https://fonts.googleapis.com/css2family=Source+Sans+Pro:wght@400;600;700&display=swap');


/* General styling */
html,
body, 
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}

.app {
    display: flex;
    position: relative;
}

table {
    width: 100%;
}

.periodCard th {
    text-align: left;
    width: 20%;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-track:hover {
    background: #555;
}

.text-center {
    text-align: center;
}

/* Mobile styling */
.login-box {
    padding: 16px 32px; 
    border-radius: 20px;
}

.luchtmacht-logo {
    height: 100px; 
    position: absolute;
    top: 0;
}

.btbb-logo {
    height: 20%; 
    width: 20%;
    padding-bottom: 96px;
}

/* Tablet and bigger styling */
@media only screen and (min-width: 600px) {
    .login-box {
        padding: 32px 64px; 
    }

    .luchtmacht-logo {
        height: 150px;
    }
}